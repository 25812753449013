import * as React from "react";

export interface VideoStreamPropsInterface {
  title: string
  style?: React.CSSProperties;
}

const VideoStream = ({
                       title,
                       style
                     }: VideoStreamPropsInterface) => {
  const [url, setUrl] = React.useState<string>("/api-public/v1/video/play/" + title);
  return <video playsInline
                controls
                preload="auto"
                style={style ? style : {width: '100%', height: 'auto', maxHeight: '80vh'}}
                src={url}/>

}

export default VideoStream;