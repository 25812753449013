import "./footer.scss"
import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFacebook, faInstagram, faSoundcloud, faYoutube} from '@fortawesome/free-brands-svg-icons';
import {Link} from 'react-router-dom';
import {Grid, useTheme} from "@mui/material";
import Box from "@mui/material/Box";
import DarkModeSwitch from "../../inputComponents/DarkModeSwitch";

const Footer = () => {
  const theme = useTheme();
  const color = theme.palette.mode === 'dark'
      ? theme.palette.text.primary
      : theme.palette.background.default

  return (<Box sx={{
        p: {color},
        a: {color}
      }}>
        <Grid container>
          <Grid item xs={12} lg={3} className="center-flex footer-left">
            <p>
              Vibe Community - elektronische Musik und Kultur e.V.
            </p>
            <p>
              34471 Volkmarsen
            </p>
            <p>
              info@vibe-community.de
            </p>
          </Grid>
          <Grid item xs={12} lg={3} className="center-flex">
            <p>
              <a href="https://www.instagram.com/vibecommunity" target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faInstagram}/>&nbsp;
                Instagram
              </a>
            </p>
            <p>
              <a href="https://www.facebook.com/VibeCommunityDE" target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faFacebook}/>&nbsp;
                Facebook
              </a>
            </p>
            <p>
              <a href="https://www.youtube.com/@Vibe-Community_e.V." target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faYoutube}/>&nbsp;
                YouTube
              </a>
            </p>
            <p>
              <a href="https://soundcloud.com/vibe_community" target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faSoundcloud}/>&nbsp;
                Soundcloud
              </a>
            </p>
          </Grid>
          <Grid item xs={12} lg={3} className="center-flex">
            <p>
              <a href="https://ticket.vibe-community.de" target="_blank" rel="noreferrer">
                Ticket-Shop
              </a>
            </p>
            <p>
              <Link to="/become-member" id="jh-create-entity">
                Mitglied werden
              </Link>
            </p>
            <p>
              <Link to="/ticket" id="jh-create-entity">
                Für Veranstalter
              </Link>
            </p>
            <p>
              <Link to="/conditions" id="jh-create-entity">
                AGB
              </Link>
            </p>
          </Grid>
          <Grid item xs={12} lg={3} className="center-flex footer-right">
            <p>
              <Link to="/contact" id="jh-create-entity">
                Kontakt
              </Link>
            </p>
            <p>
              <Link to="/imprint" id="jh-create-entity">
                Impressum
              </Link>
            </p>
            <p>
              <Link to="/privacy" id="jh-create-entity">
                Datenschutz
              </Link>
            </p>
            <DarkModeSwitch/>
          </Grid>
        </Grid>
      </Box>
  );
}

export default Footer;
