import * as React from "react";
import {ReactNode, useState} from "react";
import {inputFieldNames} from "./MemberRequestFormConstants";
import {AxiosResponse} from "axios";
import BasicDataForm from "../form/BasicDataForm";
import {ApiType} from "../../provider/useHttp";
import {Link} from "react-router-dom";

export interface MemberRequestInterface {
  id: number
  name: string
  fromEmail: string
  phone: string
  message: string
}

export interface MemberRequestFormPropsInterface {
  buttons?: ReactNode[]
  onSuccess?: (response: AxiosResponse<MemberRequestInterface>) => void
  onFailure?: (response: AxiosResponse<MemberRequestInterface>) => void
}

const MemberRequestForm = ({
                              buttons,
                              onSuccess,
                              onFailure
                            }: MemberRequestFormPropsInterface) => {
  const [value, setValue] = useState();

  const newButtons = [
    <p>
      Informationen zur Verarbeitung deiner personenbezogenen Daten im Zusammenhang mit dem
      Kontaktformular findest du in unserer <Link to="/privacy" id="jh-create-entity">
      Datenschutzerklärung
    </Link>.
    </p>
  ]

  buttons?.forEach(value1 => newButtons.push(value1 as JSX.Element))

  return (<BasicDataForm
      url="/member-request"
      value={value}
      setValue={setValue}
      fieldConfig={inputFieldNames}
      buttons={newButtons}
      onSuccess={onSuccess}
      onFailure={onFailure}
      apiType={ApiType.PUBLIC}
  />)
}


export default MemberRequestForm;