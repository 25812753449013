import {FieldType, TextFieldConfigInterface} from "../form/Form";

export const inputFieldNames: Array<TextFieldConfigInterface> = [
  {
    id: "name",
    title: "Name",
    type: FieldType.TEXT,
    required: true
  },
  {
    id: "fromEmail",
    title: "EMail",
    type: FieldType.TEXT,
    required: true
  },
  {
    id: "phone",
    title: "Telefon",
    type: FieldType.TEXT,
    required: true
  },
  {
    id: "birthday",
    title: "Geburtstag",
    type: FieldType.DATE,
    required: true
  },
  {
    id: "message",
    title: "Nachricht",
    type: FieldType.BIG_TEXT,
    required: true
  },
]