import * as React from 'react';
import {Card, CardContent} from "@mui/material";
import Side from "../../components/content/Side";
import Typography from "@mui/material/Typography";
import MemberRequestForm from "../../components/member/MemberRequestForm";

const MemberRequest = ({children}: any) => {
  return (
      <Side title="Mitglied werden 📇">
        <Card>
          <CardContent>
            <Typography component="h2" variant="h3">
              Werde Teil dieser Community!
            </Typography>
            <Typography component="p">
              Schreib uns eine Nachricht, wenn du Mitglied werden möchtest.
            </Typography>
            <Typography component="p">
              In deiner Nachricht sollte möglichst enthalten sein, wie du auf uns aufmerksam
              geworden bist und warum du dabei sein möchtest.
            </Typography>
            <Typography component="p">
              Wir melden uns bei dir, sobald wir deine Anfrage bearbeitet haben.
            </Typography>
            <br/>
            <MemberRequestForm/>
          </CardContent>
        </Card>
      </Side>
  )
}

export default MemberRequest