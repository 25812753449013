import React, {createContext, useCallback, useEffect, useState} from "react";
import useGet from "./useGet";
import {ApiType} from "./useHttp";
import {
  SystemFeatureInterface,
  SystemPropertyInterface,
  SystemStateInterface
} from "../components/systemState/SystemState";

export const SystemStateContext = createContext<{
  properties: SystemPropertyInterface[],
  features: SystemFeatureInterface[],
  isFeatureEnabled: (val: string) => boolean
}>({properties: [], features: [], isFeatureEnabled: (val: string) => false});

const MainStateProvider = (({children}: { children: React.ReactNode }) => {
  const [value, setValue] = useState<SystemStateInterface>()

  const get = useGet<SystemStateInterface, any>({
    apiType: ApiType.PUBLIC,
    url: "/system-state",
    noValue: true,
    user: null
  });

  useEffect(() => {
    if (!!get.value && value !== get.value) {
      setValue(get.value)
    }
  }, [get, get.value, value, setValue]);

  const isFeatureEnabled = (featureName: string) => {
    if (!!value?.features) {
      const foundFeature = value?.features.find(feature => feature.title === featureName);
      return !!foundFeature && foundFeature.value
    }
    return false;
  }

  const getContextValue = useCallback(
      () => ({
        properties: value?.properties || [],
        features: value?.features || [],
        isFeatureEnabled
      }), [value])

  return <SystemStateContext.Provider value={getContextValue()}>
    {children}
  </SystemStateContext.Provider>
})

export default MainStateProvider;