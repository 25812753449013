import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import * as React from "react";
import VideoStream from "../videoStream/VideoStream";
import {useMediaQuery, useTheme} from "@mui/material";

const ReactMarkdownRender = (props: { markdown: string }) => {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));

  return <ReactMarkdown
      children={props.markdown}
      components={{
        img: ({node, ...props}) => {
          if (props.src && props.alt === "video") {
            return <VideoStream title={props.src}/>
          }

          return <img alt={props.alt} src={props.src}/>;
        }
      }}
      remarkPlugins={[remarkGfm]}
  />;
}

export default ReactMarkdownRender;